.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    color: black !important;
  }

  .register-logo {
    display: block;
    max-width: 250px;
    margin: 0 auto 1rem;
    margin-bottom: -30px;
  }
  
  .register-form {
    position: relative;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    box-shadow: none;
  }
  
  .register-form input[type="text"],
  .register-form input[type="password"],
  .register-form input[type="email"] {
    color: white;
    background-color: #121111;
    padding: 1rem;
    border: 1px solid #a19f9f;
    border-radius: 25px;
    outline: none;
    margin-bottom: 1rem;
  }
  
  .register-form button {
    padding: 1rem;
    background-color: #3a4ae2;
    color: #fff;
    font-size: 13px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .register-form button:hover {
    background-color: #495BFA;
  }
  
  .login-text {
    color: grey;
    text-align: center;
    margin-top: 1rem;
    font-size: 15px !important;
  }
  
  .login-text a {
    text-decoration: none;
    color: #2464b3;
  }
  
  .login-text a:hover {
    text-decoration: underline;
  }

  .error {
    background-color: rgba(255, 0, 0, 0.582); /* Transparent red background */
    border: red solid 2px;
    color: white;
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
  }
  
