.verify-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 90vh;
    margin-top: 100px;
    color: white !important;
  }
  
  .verify-form {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    box-shadow: none;
  }
  
  .verify-form input[type="email"],
  .verify-form input[type="text"] {
    background-color: #121111;
    color: white;
    padding: 1rem;
    border: 1px solid #a19f9f;
    border-radius: 25px;
    outline: none;
    margin-bottom: 1rem;
  }
  
  .verify-form button {
    padding: .8rem;
    background-color: #495BFA;
    color: #fff;
    font-size: 13px !important;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .verify-form button:hover {
    background-color: #3344d8;
  }
  
  .error {
    background-color: rgba(255, 0, 0, 0.582);
    color: white;
    padding: 1rem;
    border-radius: 25px;
    text-align: center;
  }
  