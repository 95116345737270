@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0;
  color: #333;
}

.unselectable {
    user-select: none;             /* Standard syntax */
    -webkit-user-select: none;     /* Chrome, Safari */
    -moz-user-select: none;        /* Firefox */
    -ms-user-select: none;         /* Internet Explorer/Edge */
}

.app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #0f0f0f;
}

.top-bar {
    position: fixed;
    top: 25px;
    left: 150px;
    right: 150px;
    height: 65px;
    background-color: #171716;
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Aligns greeting and gear icon on opposite sides */
    padding: 0 30px;
    z-index: 100;
}

.left-content {
  display: flex;
  align-items: center;
}

.tasks-buttons {
    padding-right: 35px;
}

.top-bar .greeting {
  color: white !important;
  font-size: 19px;
  margin-right: 50px;
}

.top-bar .statistics-date {
  color: grey !important;
  font-size: 15px;
  margin-right: 50px;
}

.top-bar .statistics-due {
  color: grey !important;
  font-size: 15px;
  margin-right: 50px;
}

.settings-gear {
    cursor: pointer;
    font-size: 16px;
}
.filter-buttons {
    display: flex;
    justify-content: space-between; /* Ensures that left and right buttons are aligned to opposite ends */
    margin-top: 100px; /* Adjusts the space below the top bar */
    margin-left: 150px; /* Aligns with the left side of the top bar */
    margin-right: 150px; /* Aligns with the right side of the top bar */
    align-items: center; /* Vertically centers the buttons in the container */
}

.filter-buttons button {
    background-color: #171716;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px; /* Adds spacing between buttons */
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-buttons button.active {
    background-color: #343434; /* Slightly different background for active state */
}

.file-system-viewer {
    color: white !important;
    font-family: monospace;
    font-size: 17px;
    margin: 20px 150px 150px; /* Adjust left and right margins, add bottom padding */
    height: calc(100vh - 210px); /* Adjust height to take full vertical space minus offsets */
    overflow-y: auto; /* Allows scrolling */
    box-sizing: border-box; /* Includes padding in height calculation */
}

.grid-item i {
    font-size: 24px;  /* Increase the font size as needed */
    margin-bottom: 8px;  /* Optional: Add some space below the icon */
}

.file-item {
    display: flex;
    justify-content: space-between; /* Aligns items on the left and right */
    padding: 5px 10px;
    cursor: pointer;
}

.file-item.selected {
    background-color: #4444; /* Slightly lighter than the viewer's background */
}

.last-accessed {
    margin-left: auto; /* Pushes the date to the right */
    white-space: nowrap; /* Prevents date from wrapping */
}
.grid-view {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* 6 items per line */
    gap: 20px; /* Spacing between grid items */
    padding: 20px;
    margin-left: 150px; /* Align with the rest of the content */
    margin-right: 150px;
}

.grid-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px; /* Constant padding */
    cursor: pointer;
    transition: box-shadow 0.3s ease; /* Smooth transition for box-shadow */
    box-shadow: inset 0 0 0 0px #555; /* Initially no visible inset shadow */
    border: 2px solid transparent; /* Transparent border to prevent layout shift */
}

.action-icons {
    display: none;
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

.grid-item:hover .action-icons {
    display: block; /* Show action icons on hover */
}

.icon {
    margin-left: 5px;
    color: #fff; /* White icon color */
}

.grid-item:hover {
    box-shadow: inset 0 0 0 2px #555; /* Inset shadow acts as a border */
}


.grid-item.folder i, .grid-item.folder .file-name {
    color: white; /* Folder icon and name color */
}

.grid-item.file i, .grid-item.file .file-name {
    color: grey; /* File icon and name color */
}

.file-name {
    margin-top: 5px; /* Space between icon and text */
}
.search-bar {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-left: 150px;
    margin-right: 150px;
}

.find-file {
    color: #007BFF;  /* Blue color */
    margin-right: 10px; /* Adds some space between the label and the path */
}

.path {
    background-color: rgba(255, 255, 255, 0.1); /* White with 30% opacity */
    color: white; /* Ensuring the path is readable against the light background */
    padding: 3px 6px; /* Slight padding around the path for better visibility */
    border-radius: 4px; /* Optionally rounding the corners */
}


#search-input {
    flex-grow: 1;
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    outline: none;
}
.task-view {
    color: white !important;
    font-family: monospace;
    font-size: 17px;
    margin: 20px 150px 150px; /* Adjust margins to align with file-system-viewer */
    height: calc(100vh - 210px); /* Adjust height similarly */
    overflow-y: auto; /* Allows scrolling */
    box-sizing: border-box; /* Include padding in height calculation */
}

.task-item {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;  /* Adjust these fractions as desired */
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}

.task-title {
  font-size: 16px;
}

/* Task details styling with text truncation */
.task-details {
  font-size: 13px;
  margin-left: 10px;       /* Adds a little margin to the left of details */
  white-space: nowrap;     /* Prevents wrapping */
  overflow: hidden;        /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis at the end if text is too long */
}

/* Due date styling */
.task-due {
  text-align: right;       /* Align due date text to the right */
  font-size: 12px;
  color: #ccc;
}

.task-item.selected {
    background-color: #4444; /* Same selected state as file-item */
}
.task-view input, .task-view textarea {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
}

.modal-view {
  width: 800px;
  height: 400px;
  background-color: #171716;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-overlay .modal-title {
  font-size: 18px;
  background-color: transparent;
  text-decoration: none;
  resize: none;
  border: none;
  color: white;
}

.modal-overlay .modal-date {
  font-size: 12px;
  background-color: transparent;
  text-decoration: none;
  resize: none;
  border: none;
  color: grey;
  margin-top: -30px;
  margin-bottom: 10px;
}

.modal-overlay .modal-details {
  font-size: 14px;
  background-color: transparent;
  text-decoration: none;
  resize: none;
  border: none;
  color: white;
}


.left-buttons, .right-buttons {
    display: flex; /* Makes buttons in these divs line up horizontally */
}
.filter-buttons button:last-child {
    margin-right: 0; /* Removes margin from the last button to prevent extra spacing */
}

.filter-buttons button.active {
    background-color: #343434; /* Differentiates the active button */
}
.note-view {
    padding: 60px;
    padding-left: 150px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    top: 100px;
    left: 150px;
    right: 150px;
    z-index: 10; /* Ensures it's on top */
}

.back-button {
    font-size: 16px;
    color: #007BFF;
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 20px;
    text-align: left;
}

.back-button:hover {
    text-decoration: underline;
}

.note-view input, .note-view textarea {
    border: none;
    outline: none;
    background: transparent;
    color:  white;
    font-size: 16px;
    width: 100%;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

.note-view textarea {
    height: 75vh;
    cursor: pointer;
}

.note-title::placeholder, .note-body::placeholder {
    color: rgba(255, 255, 255, 0.5); /* Light grey placeholder */
}

.note-view .note-title {
    font-size: 36px;
    pointer: cursor;
    margin-bottom: 0;
    font-weight: 500;
}

.note-view .note-body {
    resize: none; /* Prevent resizing */
    pointer: cursor;
    font-size: 20px;
    line-height: 1.5;
}

.note-view .delete-button {
    background-color: #171716;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.note-view .delete-button::hover {
  background-color: grey;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    width: 400px;
    height: 500px;
    background-color: #171716;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal-input, .modal-textarea {
    background-color: transparent;
    color: white;
    border: none;
    padding: 10px;
    outline: none;
    width: 100%;
}

.modal-input {
    font-size: 24px; /* Increased font size for the title */
    padding-bottom: 5px; /* Reduced padding below the title */
}

.modal-input[type="date"] {
    font-size: 16px;
    margin-top: 10px; /* Reduced the gap between title and date */
}

.modal-textarea {
    border-top: 1px solid rgba(255, 255, 255, 0.5); /* Half-opacity grey border */
    height: 200px;
    resize: none;
}

.create-button {
    margin-top: auto;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.update-button {
    padding: 10px 20px;
    background-color: #221170;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.close-button {
    padding: 10px 20px;
    background-color: #221170;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.modal-buttons {
  display: flex;
  gap: 10px;          /* Adds a gap between the buttons */
  margin-top: auto;    /* Adds some space above the buttons */
  width: 100%;        /* Ensures the container fills the modal's width */
}

.modal-buttons button {
  flex: 1;            /* Makes each button take equal space */
}


.create-button:hover {
    background-color: #0056b3;
}
